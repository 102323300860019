<template>
    <Message />
</template>

<script>
import Message from '_c/message/message'
export default {
  name: 'Messagffe',
  components: {
    Message
  }
}
</script>

<style scoped>

</style>
